.main-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
}

.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 65px;
}

.headline {
  font-size: 14px;
  padding-top: 8px;
  font-weight: 300;
  color: #363434;
  text-align: center;
  /* font-family: 'Cairo'; */
}

.intro-message-container {
  max-width: 625px;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 58px;
}

.intro-text {
  font-size: 21px;
  line-height: 1.6em;
  /* font-weight: 800; */
  padding-bottom: 24px;
  /* font-family: 'Cairo-Black'; */
}

.intro-participate {
  font-size: 16px;
  font-weight: 300;
  color: #615e5e;
  /* font-family: 'Cairo'; */
}

.description-container {
  text-align: left;
  padding-top: 47px;
  padding-bottom: 48px;
  background: #fefffc;
}

.description-content-area {
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
}

.description-title {
  font-size: 16px;
  font-weight: 400;
  /* font-family: 'Cairo'; */
}

.description-unit {
  padding-top: 22px;
  padding-left: 12px;
  display: flex;
}

.description-unit::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-top: 9px;
  margin-right: 17px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #69b6d5;
  background: #000;
}

.description-unit-container {
  padding-top: 6px;
}

.unit-headline {
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 700;
  /* font-family: 'Cairo'; */
}

.unit-tagline {
  font-size: 16px;
  font-weight: 200;
  /* font-family: 'Cairo'; */
}

.participate-container {
  text-align: center;
  padding-top: 22px;
  padding-bottom: 61px;
}

.participate-link {
  font-size: 24px;
  font-weight: 200;
  color: #615e5e;
  /* font-family: 'Cairo'; */
}

.anounce-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 49px
}

.anounce-headline {
  font-size: 18px;
  font-weight: 400;
  /* font-family: 'Cairo'; */
}

.anounce-invitation {
  font-size: 14px;
  font-weight: 400;
  color: #231fe9;
  /* color: #615e5e; */
  /* font-family: 'Cairo'; */
}